import { Suspense } from "react";
import Router from "./routes";
import ThemeConfig from "./theme";

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ThemeConfig>
        <Router />
      </ThemeConfig>
    </Suspense>
  );
}

export default App;
