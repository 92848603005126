import { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

const DashboardLayout = lazy(() => import("./layouts/dashboard"));
const LogoOnlyLayout = lazy(() => import("./layouts/LogoOnlyLayout"));
const AccountEdit = lazy(() => import("./pages/AccountEdit"));
const AccountNew = lazy(() => import("./pages/AccountNew"));
const Accounts = lazy(() => import("./pages/Accounts"));
const Debts = lazy(() => import("./pages/Debts"));
const DebtNew = lazy(() => import("./pages/DebtNew"));
const DebtEdit = lazy(() => import("./pages/DebtEdit"));
const Profile = lazy(() => import("./pages/Profile"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const IncomeCalc = lazy(() => import("./pages/IncomeCalc"));
const Login = lazy(() => import("./pages/Login"));
const RandomNumber = lazy(() => import("./pages/RandomNumber"));
const SQLFormat = lazy(() => import("./pages/SQLFormat"));
const TransactionEdit = lazy(() => import("./pages/TransactionEdit"));
const TransactionNew = lazy(() => import("./pages/TransactionNew"));
const Transactions = lazy(() => import("./pages/Transactions"));
const ImageView = lazy(() => import("./pages/ImageView"));

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/main/transactions" replace={true} />,
        },
        { path: "login", element: <Login /> },
      ],
    },
    {
      path: "/main",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/main/transactions" replace={true} /> },
        { path: "/main", element: <Navigate to="transactions" /> },
        { path: "dashboard", element: <Dashboard /> },
        { path: "transactions", element: <Transactions /> },
        { path: "transactions/new", element: <TransactionNew /> },
        { path: "transactions/:id", element: <TransactionEdit /> },
        { path: "accounts", element: <Accounts /> },
        { path: "accounts/new", element: <AccountNew /> },
        { path: "accounts/:id", element: <AccountEdit /> },
        { path: "debts", element: <Debts /> },
        { path: "debts/new", element: <DebtNew /> },
        { path: "debts/:id", element: <DebtEdit /> },
        { path: "profile", element: <Profile /> },
        { path: "sqlformat", element: <SQLFormat /> },
        { path: "income-calc", element: <IncomeCalc /> },
        { path: "random", element: <RandomNumber /> },
        { path: "image", element: <ImageView /> },
      ],
    },
  ]);
}
